<template>
  <section reviews-carousel v-editable="blok" :data-style="blok.style || 'center'">
    <div class="container">
      <div class="row">
        <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
        <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
      </div>
      <div class="row">
        <Swiper ref="swiperEl" :modules="modules" :pagination="pagination" :navigation="navigation" :speed="500" :loop="true" :autoplay="{ delay: 7000, pauseOnMouseEnter: true, disableOnInteraction: false }" :slides-per-view="1" :breakpoints="breakpoints" :space-between="32" class="section-slider">
          <SwiperSlide v-for="blok in blok.reviews" :key="blok._uid">
            <StoryblokComponent :blok="blok" />
          </SwiperSlide>
          <template #container-end>
            <div class="slider-footer">
              <div class="slider-dots"></div>
              <div title="Slider Arrow" class="slider-arrows">
                <a href="#prev" title="Previous Slide" aria-label="Previous Slide" role="button" class="slider-arrow slider-prev">
                  <span class="sr-only">Previous Slide</span>
                </a>
                <a href="#next" title="Next Slide" aria-label="Next Slide" role="button" class="slider-arrow slider-next">
                  <span class="sr-only">Next Slide</span>
                </a>
              </div>
            </div>
          </template>
        </Swiper>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/reviews-carousel.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ blok: Object });

  const title = computed(() => renderRichText(props?.blok?.title || {}));
  const description = computed(() => renderRichText(props?.blok?.description || {}));

  const modules = [SwiperAutoplay, SwiperPagination, SwiperNavigation];

  const navigation = {
    nextEl: ".slider-next",
    prevEl: ".slider-prev",
  };

  const breakpoints = {
    768: {
      slidesPerGroup: 1,
      slidesPerView: 1,
    },
    992: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
  };

  const pagination = {
    clickable: true,
    el: ".slider-dots",
  };
</script>
